import * as React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import { ContentWrapper } from "../components/ContentWrapper"
import { ContactForm } from "../components/ContactForm"
import { PrimaryButton } from "../components/PrimaryButton"
import NewPageSvg from "../images/new-page.svg"

import cx from "classix"

function Top() {
  return (
    <div className="relative m:hidden">
      <StaticImage
        src="../images/nexteam-bg.jpeg"
        alt="Background"
        className="!absolute z-0 left-0 top-0 right-0 bottom-0"
      />

      <div className="!absolute z-10 left-0 top-0 right-0 bottom-0 bg-black bg-opacity-80" />
      <ContentWrapper className="pt-[130px] pb-[50px] px-[35px] relative z-30 flex gap-x-[40px]">
        <div
          className={cx("!absolute h-[3px] w-[158px] top-[250px] left-[10px]")}
        >
          <div
            className={cx(
              "bg-gradient-to-r from-[#73D2FB] via-[#3BB2E5] to-[#3BB2E5] h-[100%] w-[100%]",
              "[animation-delay:0.75s] duration-500 animate-in fade-in-0 slide-in-from-bottom-[3px] fill-mode-both",
              "transition-colors"
            )}
          />
        </div>

        <div className="flex-1 relative z-10">
          <div className="overflow-hidden">
            <h2
              className={cx(
                "font-['Arial_Black','Arial_Bold'] text-[74px] text-[#444444]",
                "duration-500 animate-in fade-in-0 slide-in-from-bottom-[74px]",
                "transition-colors"
              )}
            >
              会社概要
            </h2>
          </div>
          <div className="overflow-hidden relative top-[-40px] left-[50px]">
            <h2
              className={cx(
                "font-['Arial_Black','Arial_Bold'] text-[74px] text-white",
                "[animation-delay:1.25s] animate-in fade-in slide-in-from-bottom-3 duration-500 fill-mode-both",
                "transition-colors"
              )}
            >
              NEXTEAM
            </h2>
          </div>

          <div className="text-lg leading-[40px] animate-in fade-in slide-in-from-bottom-3 duration-500 [animation-delay:1.5s] fill-mode-both transition-colors">
            スポーツで勝つためにはフィジカル・コンディションの向上が欠かせない。恵まれた環境にいたからこそ気づけたスポーツ界に存在する環境格差。恵まれた環境にいたからこそ思いついたスポーツ界を盛り上げるためのvision。
            <br />
            株式会社nexteamは日本のスポーツ界をさらに発展させるため日々取り組んでいます。総合トレーニングアプリwopalの開発・運営並びに部活動と勉強の両立のために必要な情報提供などの活動に力を入れており、日本のスポーツ界全体の盛り上げに欠かせない存在を目指しています。
          </div>
        </div>

        <StaticImage
          src="../images/nexteam-illustration.png"
          alt="Nexteam Illustration"
          className="w-[565px] animate-in fade-in duration-500 [animation-delay:1.75s] fill-mode-both transition-colors"
        />
      </ContentWrapper>
    </div>
  )
}

function Info() {
  return (
    <div id="info" className="relative m:hidden">
      <StaticImage
        src="../images/info-bg.png"
        alt="Info Background"
        className="!absolute z-0 left-0 top-0 right-0 bottom-0 object-bottom object-cover"
      />

      <ContentWrapper className="relative z-20 px-[120px] grid grid-cols-[auto_1fr] py-[100px] gap-x-[100px] gap-y-[30px] text-xl animate-in fade-in slide-in-from-bottom-3 [animation-delay:2s] fill-mode-both">
        <div className="font-medium text-center">会社名</div>
        <div className="opacity-75">株式会社nexteam</div>

        <div className="font-medium text-center">設立</div>
        <div className="opacity-75">2020年5月24日</div>

        <div className="font-medium text-center">資本金</div>
        <div className="opacity-75">400万円［2022年6月末時点］</div>

        <div className="font-medium text-center">事業内容</div>
        <div className="opacity-75">
          情報・通信・スポーツ事業・弊社企画アプリ
          <a
            className="text-primary-main underline"
            href="https://wopal.dev"
            target="_blank"
          >
            wopal
          </a>
          の開発・運用・販売
          <a
            className="text-primary-main underline"
            href="https://wopal.dev"
            target="_blank"
          >
            <NewPageSvg className="inline ml-[0.5rem] relative top-[-1px]" />
          </a>
        </div>

        <div className="font-medium text-center">代表者</div>
        <div className="opacity-75">菅原　峻</div>

        <div className="font-medium text-center">所在地</div>
        <div className="opacity-75">
          東京都千代田区大手町1-7-2 東京サンケイビル 27F
        </div>
      </ContentWrapper>

      <ContentWrapper className="relative z-10 mt-[140px]" id="contact">
        <h2 className="text-3xl text-center mb-[40px]">お問い合わせ</h2>
        <p className="text-xl leading-loose text-center mb-[60px]">
          皆さんからのフィードバック、ご要望、利用相談など、何でもお待ちしております。
          <br />
          是非お気軽にご連絡ください。
        </p>

        <ContactForm />

        <div className="mt-[150px] text-sm text-[#BDBDBD] text-center pb-[100px]">
          Copyright ©nexteam {new Date().getFullYear()}
        </div>
      </ContentWrapper>
    </div>
  )
}

function TopMobile() {
  return (
    <div className="relative d:hidden px-[16px] py-[70px]">
      <StaticImage
        src="../images/nexteam-bg.jpeg"
        alt="Background"
        className="!absolute z-0 left-0 top-0 right-0 bottom-0 object-bottom object-cover"
      />
      <div className="!absolute z-10 left-0 top-0 right-0 bottom-0 bg-black bg-opacity-80" />

      <div className="relative z-10">
        <h1
          className={cx(
            "text-[50px] font-['Arial_Black','Arial-BoldMT'] text-center mb-[1.5rem]",
            "[animation-delay:0s] duration-500 animate-in fade-in-0 slide-in-from-bottom-[3px] fill-mode-both"
          )}
        >
          NEXTEAM
        </h1>

        <p
          className={cx(
            "text-center leading-[2.4]",
            "[animation-delay:0.25s] duration-500 animate-in fade-in-0 slide-in-from-bottom-[3px] fill-mode-both"
          )}
        >
          スポーツで勝つためには
          <br />
          フィジカル・コンディションの向上が
          <br />
          欠かせない。
          <br />
          恵まれた環境にいたからこそ
          <br />
          気づけたスポーツ界に
          <br />
          存在する環境格差。
          <br />
          恵まれた環境にいたからこそ
          <br />
          思いついたスポーツ界を
          <br />
          盛り上げるためのvision。
          <br />
          株式会社nexteamは
          <br />
          日本のスポーツ界をさらに
          <br />
          発展させるため
          <br />
          日々取り組んでいます。
          <br />
          総合トレーニングアプリwopalの
          <br />
          開発・運営並びに
          <br />
          部活動と勉強の両立のために
          <br />
          必要な情報提供などの活動に
          <br />
          力を入れており、
          <br />
          日本のスポーツ界全体の盛り上げに
          <br />
          欠かせない存在を
          <br />
          目指しています。
          <br />
        </p>

        <div
          className={cx(
            "flex justify-center my-[1rem]",
            "[animation-delay:0.5s] duration-500 animate-in fade-in-0 slide-in-from-bottom-[3px] fill-mode-both"
          )}
        >
          <a href="#info-mobile">
            <PrimaryButton>詳しく見る</PrimaryButton>
          </a>
        </div>
      </div>
    </div>
  )
}

function InfoMobile() {
  const renderKeyValue = (key: React.ReactNode, value: React.ReactNode) => {
    return (
      <>
        <div className="text-lg mb-[1rem] relative z-10">{key}</div>
        <div className="text-base opacity-75 mb-[1.5rem] relative z-10">
          {value}
        </div>
      </>
    )
  }

  return (
    <div id="info-mobile" className="d:hidden">
      <div className="relative h-[200px] flex justify-center items-center">
        <StaticImage
          src="../images/nexteam-illustration.png"
          alt="Nexteam Illustration"
          className="!absolute z-0 left-0 top-0 right-0 bottom-0 object-bottom object-cover"
        />

        <h2 className="text-xl relative z-10 font-medium">会社概要</h2>
      </div>

      <div className="px-[24px] py-[50px] relative">
        <StaticImage
          src="../images/info-bg.png"
          alt="Info Background"
          className="!absolute z-0 left-0 top-0 right-0 bottom-0 object-bottom object-cover"
        />

        {renderKeyValue(<>会社名</>, <>株式会社nexteam</>)}
        {renderKeyValue(<>設立</>, <>2020年5月24日</>)}
        {renderKeyValue(<>資本金</>, <>400万円［2022年6月末時点］</>)}
        {renderKeyValue(
          <>事業内容</>,
          <>
            情報・通信・スポーツ事業
            <br />
            弊社企画アプリ
            <a
              className="text-primary-main underline"
              href="https://wopal.dev"
              target="_blank"
            >
              wopal
            </a>
            の
            <br />
            開発・運用・販売
          </>
        )}
        {renderKeyValue(<>代表者</>, <>菅原　峻</>)}
        {renderKeyValue(
          <>所在地</>,
          <>
            東京都千代田区大手町1-7-2
            <br />
            東京サンケイビル 27F
          </>
        )}
      </div>

      <div className="bg-bg-dark px-[24px] py-[50px]" id="mobile-contact">
        <h2 className="font-medium text-lg text-center mb-[1.5rem]">
          お問い合わせ
        </h2>

        <p className="text-center leading-[2.4] mb-[3rem]">
          皆さんからのフィードバック、
          <br />
          ご要望、利用相談など、
          <br />
          何でもお待ちしております。
          <br />
          是非お気軽にご連絡ください。
          <br />
        </p>

        <ContactForm />

        <div className="mt-[5rem] text-sm text-[#BDBDBD] text-center pb-[4rem]">
          Copyright ©nexteam {new Date().getFullYear()}
        </div>
      </div>
    </div>
  )
}

export default function Index() {
  return (
    <Layout>
      <Top />
      <TopMobile />
      <Info />
      <InfoMobile />
    </Layout>
  )
}

export const Head = () => <Seo title="INTRO" />
