import { useCallback, useState } from "react";

export const id = <T>(v: T): T => {
  return v;
};

export const useAsync = <T, V, E = string>(
  asyncFunction: (v: V) => Promise<T>
) => {
  const [status, setStatus] = useState<
    "idle" | "pending" | "success" | "error"
  >("idle");
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState<E | null>(null);
  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = useCallback(
    async (v: V) => {
      setStatus("pending");
      setValue(null);
      setError(null);
      try {
        const response = await asyncFunction(v);
        setValue(response);
        setStatus("success");
      } catch (error) {
        setError(error as any);
        setStatus("error");

        throw error;
      }
    },
    [asyncFunction]
  );
  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.

  return { execute, status, value, error };
};
