export type Mode = "prod" | "staging" | "local";

export interface Config {
  mode: Mode;
  serverUrl: string;
  recaptchaPublicKey: string;
  demo: boolean;
}

export const config: Config = (() => {
  const mode = process.env.GATSBY_STAGE as Mode;
  const recaptchaPublicKey = "6LfLCAYjAAAAACn0U6a-tlvd5663KLbNdVGYtVvM";

  switch (mode) {
    case "prod":
      return {
        mode,
        recaptchaPublicKey,
        serverUrl: "https://homepage-server.nexteam.jp/api",
        demo: false
      };
    case "staging":
      return {
        mode,
        recaptchaPublicKey,
        serverUrl: "https://homepage-server.nexteam.jp/api",
        demo: false
      };
    case "local":
      return {
        mode,
        recaptchaPublicKey,
        // serverUrl: "http://localhost:7071/api",
        serverUrl: "https://homepage-server.nexteam.jp/api",
        demo: false
      };
  }
})();