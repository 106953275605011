import { config } from "../config"

export const postContact = async (body: FormData) => {
  return await fetch(`${config.serverUrl}/contact/`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(body as any).toString(),
  })
}
