import React, { useState } from "react"

import cx from "classix"
import { load as loadRecaptcha } from "recaptcha-v3"

import { PrimaryButton } from "./PrimaryButton"
import { useAsync } from "../utils/types"
import { postContact } from "../apis/contact"
import { config } from "../config"

export const ContactForm = () => {
  const [error, setError] = useState("")

  const submit = useAsync(async (body: FormData) => {
    setError("")

    const recaptchaInstance = await loadRecaptcha(config.recaptchaPublicKey, {
      useRecaptchaNet: true,
    })

    const token = await recaptchaInstance.execute("contact")

    body.append("captcha", token)

    const response = await postContact(body)

    if (!response.ok) {
      setError(await response.text())
      return
    }

    location.href = "/contact-complete"
  })

  const renderInput = (
    label: string,
    name: string,
    type: string,
    required: boolean
  ) => {
    return (
      <input
        className={cx(
          "p-[19px] bg-gray-100 border border-solid border-gray-200",
          "text-sm text-gray-700 placeholder:text-gray-400 rounded-full disabled:!text-gray-400"
        )}
        placeholder={label + (required ? "*" : "")}
        required={required}
        type={type}
        id={name}
        name={name}
        disabled={submit.status === "pending"}
      />
    )
  }

  return (
    <form
      className="bg-white d:p-[52px] m:p-[1.5rem] flex flex-col d:gap-y-[32px] m:gap-y-[16px]  rounded-[20px]"
      onSubmit={async e => {
        e.preventDefault()

        const body = new FormData(e.currentTarget)
        await submit.execute(body)
      }}
    >
      {renderInput("名前", "name", "text", true)}
      {renderInput("メールアドレス", "from_email", "email", true)}
      {renderInput("電話番号", "phone", "phone", false)}
      {renderInput("タイトル", "subject", "text", true)}

      <textarea
        name="message"
        required
        placeholder="お問い合わせ内容*"
        rows={6}
        className={cx(
          "p-[19px] bg-gray-100 border border-solid border-gray-200",
          "text-sm text-gray-700 placeholder:text-gray-400 disabled:!text-gray-400"
        )}
        disabled={submit.status === "pending"}
      />

      {error && <div className="text-red-500">{error}</div>}

      <PrimaryButton
        type="submit"
        className="self-start w-[136px] flex justify-center"
        loading={submit.status === "pending"}
      >
        送信
      </PrimaryButton>
    </form>
  )
}
